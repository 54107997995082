import { render, staticRenderFns } from "./ButtonApple.vue?vue&type=template&id=afdfcfda&scoped=true&"
import script from "./ButtonApple.vue?vue&type=script&lang=js&"
export * from "./ButtonApple.vue?vue&type=script&lang=js&"
import style0 from "./ButtonApple.vue?vue&type=style&index=0&id=afdfcfda&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afdfcfda",
  null
  
)

export default component.exports