<template>
  <button
    class="gpay-button black long brdr-none w-100"
    :class="this.$i18n.locale"
    @click="onClick"
  />
</template>

<script>
import focusClean from 'theme/components/theme/directives/focusClean'
export default {
  name: 'ButtonGoogle',
  directives: { focusClean },
  props: {
    onClick: {
      type: Function,
      required: false,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
    .gpay-button.black.long.pl-PL {
        background-image: url(/assets/google-pay-button-pl.svg);
    }
    .gpay-button.black.long.en-US {
        background-image: url(/assets/google-pay-button-en.svg);
    }
    .gpay-button.black.long.de-DE {
        background-image: url(/assets/google-pay-button-de.svg);
        padding: 10px 0;
    }
    .gpay-button.long {
        min-width: 172px;
    }
    .gpay-button.black {
        background-color: #000;
        box-shadow: none;
    }
    .gpay-button {
      background-origin: content-box;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      border: 0px;
      /* border-radius: 30px; */
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 1px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      cursor: pointer;
      height: 41px;
      min-height: 40px;
    }
    button:disabled  {
      opacity: 0.3;
      pointer-events: none;
    }
</style>
