<template>
  <transition name="fade" appear>
    <div class="guide w-100 cl-tertiary" v-if="isOpen">
      <div class="container relative">
        <div class="row between-xs middle-xs px15">
          <div class="col-xs-12 start-xs">
            <img class="swipe" src="/assets/swipe-koszyk-agrosimex.gif" alt="swipe-koszyk" width="100%">
            <span class="guide-text">{{ $t('Przesuń w lewo, aby zmienić ilość lub usunąć produkt.') }}</span>
            <i
              class="icon-close icon pointer close-guide"
              @click="accept"
              @keyup.enter="accept"
              data-testid="closeguideButton"
              tabindex="0"
              role="button"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    accept () {
      this.setVisited()
      this.isOpen = false
    },
    setVisited () {
      this.$store.dispatch('claims/set', { claimCode: 'guideAccepted', value: true })
    }
  },
  created () {
    this.$store.dispatch('claims/check', { claimCode: 'guideAccepted' }).then((guideClaim) => {
      if (!guideClaim) {
        this.isOpen = true
        this.$store.dispatch('claims/set', { claimCode: 'guideAccepted', value: false })
      } else {
        this.isOpen = !guideClaim.value
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-main-gray: color(main-gray);
$color-main: color(main);

.swipe {
  filter: grayscale(1);
  position: absolute;
  left: 0;
  top: 25px;
  width: 300px;
  @media (max-width: 320px) {
    width: 200px;
    top: 35px;
  }
}
.guide {
  .container {
    display: flex;
    align-items: center;
    height: 89px;
    color: $color-main;
    background-color: #9c9c9c26;
    margin: 10px 20px 0 20px;
  }
  // z-index: 2;
  // top: 46px;
}

.close-guide {
  position: absolute;
  top: 0;
  right: 0;
}
.guide-text {
  position: absolute;
  top: 17px;
  right: 0;
  max-width: 160px;
  font-size: 12px;
}
</style>
