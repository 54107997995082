<template>
  <div id="number-input" :class="{'bg-cl-light-white brdr-30px': !isProductPage}" class="flex w-100">
    <div :class="isProductPage ? 'product-page-counter counter w-100 bg-cl-light-white' : 'default-counter counter'">
      <i @click="$emit('click', decreaseValue)" class="icon-minus" :class="{'minus-pos': isProductPage}" />
      <label>
        <span class="visually-hidden">{{ inputValue }}</span>
        <input
          :id="getInputId"
          type="number"
          :min="min"
          :max="max"
          :disabled="disabled"
          class="m0 no-outline base-input-number__input brdr-cl-primary bg-cl-transparent h4"
          :focus="autofocus"
          v-model="inputValue"
          @blur="$emit('blur', $event.target.value)"
          :readonly="incrementsStep > 1"
        >
      </label>
      <i @click="$emit('click', increaseValue)" class="icon-plus" :class="{'plus-pos': isProductPage}" />
      <ValidationMessages class="base-input-error-msg" v-if="validations" :validations="validations" />
    </div>
  </div>
</template>

<script>
import ValidationMessages from './ValidationMessages.vue'
export default {
  name: 'BaseInput',
  components: {
    ValidationMessages
  },
  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    incrementsStep: {
      type: Number,
      default: 1
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    validations: {
      type: Array,
      default: () => []
    },
    isProductPage: {
      type: Boolean,
      default: false
    },
    onlyPositive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getInputId () {
      return `input_${this._uid}`
    },
    inputValue: {
      get () {
        return this.value
      },
      set (value) {
        if (!this.onlyPositive) {
          this.$emit('input', value)
        } else {
          const targetValue = parseInt(value, 10)
          if (!isNaN(targetValue)) {
            this.$emit('input', targetValue !== 0 ? Math.abs(targetValue) : 1)
          }
        }
      }
    },
    decreaseValue () {
      let newValue = this.value
      if (newValue <= this.min) {
        newValue = this.min
      } else {
        newValue -= this.incrementsStep
      }
      return newValue
    },
    increaseValue () {
      let newValue = this.value
      newValue += this.incrementsStep
      return newValue
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-main: color(main);
$color-matterhorn: color(matterhorn);

/deep/.base-input-error-msg {
  span {
    left: 0;
    bottom: -15px;
  }
}
.minus-pos {
  position: absolute;
  left: 25px;
}
.plus-pos {
  position: absolute;
  right: 25px;
}
div#number-input {
  .product-page-counter {
    input[type="number"] {
      font-size: 18px;
    }
  }
  .default-counter {
    width: 100%;
    input[type="number"] {
      height: 35px !important;
      color: black;
      font-size: 16px !important;
    }
    i {
      font-size: 14px !important;
      color: black;
    }
  }
  .counter {
    display: flex;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    input[type="number"] {
      @media (max-width: 767px) {
        height: 40px;
      }
      border: 0;
      text-align: center;
      height: 60px;
      padding: 0;
      line-height: 30px;
      font-size: 20px;
      width: 35px;
      border-radius: 0;
    }
    input[type="number"]:focus,
    i:focus {
      outline: none;
    }
    i {
      padding: 0;
      font-size: 18px;
      text-align: center;
      height: 26px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}
</style>
